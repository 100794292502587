@use "sass:math";
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
//
// Functions
// --------------------------------------------------

/**
 * We need a clone of bootstrap color-yiq mixin so we can get the same value for color
 */
@function bs4-color-yiq($color) {
    $r: red($color);
    $g: green($color);
    $b: blue($color);

    $yiq: math.div((($r * 299) + ($g * 587) + ($b * 114)), 1000);

    @if ($yiq >=150) {
        @return "#111";
    }

    @else {
        @return "#fff";
    }
}

//
// Variables
// --------------------------------------------------

// Variables directly translating Bootstrap variables
// -------------------------

$s2bs-enable-shadows: $enable-shadows  !default;
$s2bs-border-radius-base: $border-radius  !default;
$s2bs-border-radius-large: $border-radius-lg  !default;
$s2bs-border-radius-small: $border-radius-sm  !default;
$s2bs-btn-default-bg: $input-group-addon-bg  !default; // or $gray-200
$s2bs-btn-default-border: theme-color("secondary") !default;
$s2bs-btn-default-color: bs4-color-yiq($s2bs-btn-default-bg) !default;
$s2bs-caret-width-base: .25rem !default; // 4px
$s2bs-caret-width-large: .3125rem !default; // 5px

$s2bs-cursor-disabled: not-allowed !default;
$s2bs-dropdown-header-color: $dropdown-header-color  !default;
$s2bs-dropdown-link-active-bg: $dropdown-link-active-bg  !default;
$s2bs-dropdown-link-active-color: $dropdown-link-active-color  !default;
$s2bs-dropdown-link-disabled-color: $dropdown-link-disabled-color  !default;
$s2bs-dropdown-link-hover-bg: $dropdown-link-hover-bg  !default;
$s2bs-dropdown-link-hover-color: $dropdown-link-hover-color  !default;
$s2bs-font-size-base: $font-size-base  !default;
$s2bs-font-size-large: $font-size-lg  !default;
$s2bs-font-size-small: $font-size-sm  !default;

@if variable-exists(input-font-size) {
    // Added in Bootstrap 4.2
    //noinspection SassScssUnresolvedVariable
    $s2bs-font-size-base: $input-font-size  !default;
}

@if variable-exists(input-font-size-lg) {
    // Added in Bootstrap 4.2
    //noinspection SassScssUnresolvedVariable
    $s2bs-font-size-large: $input-font-size-lg  !default;
}

@if variable-exists(input-font-size-sm) {
    // Added in Bootstrap 4.2
    //noinspection SassScssUnresolvedVariable
    $s2bs-font-size-small: $input-font-size-sm  !default;
}

$s2bs-padding-base-horizontal: $input-btn-padding-x  !default;
$s2bs-padding-large-horizontal: $input-btn-padding-x-lg  !default;
$s2bs-padding-small-horizontal: $input-btn-padding-x-sm  !default;
$s2bs-padding-base-vertical: $input-btn-padding-y  !default;
$s2bs-padding-large-vertical: $input-btn-padding-y-lg  !default;
$s2bs-padding-small-vertical: $input-btn-padding-y-sm  !default;
$s2bs-line-height-base: $input-btn-line-height  !default;
$s2bs-line-height-large: $input-btn-line-height  !default;
$s2bs-line-height-small: $input-btn-line-height  !default;
$s2bs-input-bg: $input-bg  !default;
$s2bs-input-bg-disabled: $input-disabled-bg  !default;
$s2bs-input-color: $input-color  !default;
$s2bs-input-color-placeholder: $input-placeholder-color  !default;
$s2bs-input-border: $input-border-color  !default;
$s2bs-input-border-focus: $input-focus-border-color  !default;
$s2bs-input-border-radius: $input-border-radius  !default;
$s2bs-input-height-base: $input-height  !default;
$s2bs-input-height-large: $input-height-lg  !default;
$s2bs-input-height-small: $input-height-sm  !default;
$s2bs-state-warning-text: theme-color("warning") !default;
$s2bs-state-danger-text: $form-feedback-invalid-color  !default;
$s2bs-state-success-text: $form-feedback-valid-color  !default;

// Theme-specific variables
// -------------------------

$s2bs-dropdown-arrow-color: $s2bs-input-color-placeholder  !default;
$s2bs-dropdown-box-shadow: 0 6px 12px rgba(0, 0, 0, .175) !default;
$s2bs-dropdown-box-shadow-above: 0px -6px 12px rgba(0, 0, 0, .175) !default;
$s2bs-clear-selection-color: $s2bs-dropdown-arrow-color  !default;
$s2bs-clear-selection-hover-color: $s2bs-btn-default-color  !default;
$s2bs-remove-choice-color: $s2bs-input-color-placeholder  !default;
$s2bs-remove-choice-hover-color: $s2bs-btn-default-color  !default;
$s2bs-selection-choice-border-radius: $s2bs-border-radius-base  !default;
$s2bs-dropdown-header-padding-vertical: $s2bs-padding-base-vertical  !default;
$s2bs-dropdown-header-font-size: $s2bs-font-size-small  !default;

// Form control variables
// -------------------------
$s2bs-form-control-box-shadow: $input-box-shadow  !default;
$s2bs-form-control-focus-box-shadow: $input-focus-box-shadow  !default;
$s2bs-form-control-transition: $input-transition  !default;

//
// Mixins
// --------------------------------------------------

// @see https://github.com/twbs/bootstrap/blob/v4.0.0/scss/_forms.scss#L8
@mixin bootstrap-input-defaults {
    @include box-shadow($s2bs-form-control-box-shadow);
    @include border-radius($s2bs-input-border-radius);
    @include transition($s2bs-form-control-transition);
    background-color: $s2bs-input-bg;
    border: 1px solid $s2bs-input-border;
    color: $s2bs-input-color;
    font-size: $s2bs-font-size-base;
}

// @see https://getbootstrap.com/docs/4.0/components/forms/#validation
// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss#L388
@mixin validation-state-focus($color) {
    $shadow: inset 0 1px 1px rgba(0, 0, 0, .075),
    0 0 6px lighten($color, 20%);

    .select2-dropdown,
    .select2-selection {
        border-color: $color;
    }

    .select2-container--focus .select2-selection,
    .select2-container--open .select2-selection {
        @include box-shadow($shadow);
        border-color: darken($color, 10%);

        &:focus {
            box-shadow: 0 0 0 .2rem rgba($color, .25);
        }
    }

    &.select2-drop-active {
        border-color: darken($color, 10%);

        &.select2-drop.select2-drop-above {
            border-top-color: darken($color, 10%);
        }
    }
}

// dropdown arrow when dropdown is open
@mixin dropdown-arrow {
    .select2-selection--single {
        /**
       * Make the dropdown arrow point up while the dropdown is visible.
       */

        .select2-selection__arrow b {
            border-color: transparent transparent $s2bs-dropdown-arrow-color transparent;
            border-width: 0 $s2bs-caret-width-large $s2bs-caret-width-large $s2bs-caret-width-large;
        }
    }
}





.select2-container--bootstrap {
    display: block;




    /*------------------------------------*\
        #COMMON STYLES
    \*------------------------------------*/

    .select2-selection {
        @include bootstrap-input-defaults;
        outline: 0;

        &.form-control {
            @include border-radius($s2bs-border-radius-base);
        }
    }



    /**
     * Search field in the Select2 dropdown.
     */

    .select2-search--dropdown {
        .select2-search__field {
            @include bootstrap-input-defaults;
        }
    }

    /**
     * No outline for all search fields - in the dropdown
     * and inline in multi Select2s.
     */

    .select2-search__field {
        outline: 0;

        &::-webkit-input-placeholder {
            color: $s2bs-input-color-placeholder;
        }

        /* Firefox 18- */
        &:-moz-placeholder {
            color: $s2bs-input-color-placeholder;
        }

        /**
       * Firefox 19+
       *
       * @see http://stackoverflow.com/questions/24236240/color-for-styled-placeholder-text-is-muted-in-firefox
       */
        &::-moz-placeholder {
            color: $s2bs-input-color-placeholder;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: $s2bs-input-color-placeholder;
        }
    }

    /**
     * Adjust Select2's choices hover and selected styles to match
     * Bootstrap 4's default dropdown styles.
     *
     * @see https://getbootstrap.com/docs/4.0/components/dropdowns/
     */

    .select2-results__option {
        padding: $s2bs-padding-base-vertical $s2bs-padding-base-horizontal;

        &[role=group] {
            padding: 0;
        }

        /**
       * Disabled results.
       *
       * @see https://select2.github.io/examples.html#disabled-results
       */

        &[aria-disabled=true] {
            color: $s2bs-dropdown-link-disabled-color;
            cursor: $s2bs-cursor-disabled;
        }

        /**
       * Hover state.
       */

        &[aria-selected=true] {
            background-color: $s2bs-dropdown-link-hover-bg;
            color: $s2bs-dropdown-link-hover-color;
        }

        /**
       * Selected state.
       */

        &--highlighted[aria-selected] {
            background-color: $s2bs-dropdown-link-active-bg;
            color: $s2bs-dropdown-link-active-color;
        }

        &.select2-results__option--selected {
            background-color: $s2bs-dropdown-link-active-bg;
            color: $s2bs-dropdown-link-active-color;
        }

        .select2-results__option {
            padding: $s2bs-padding-base-vertical $s2bs-padding-base-horizontal;

            .select2-results__group {
                padding-left: 0;
            }

            .select2-results__option {
                margin-left: -$s2bs-padding-base-horizontal;
                padding-left: $s2bs-padding-base-horizontal*2;

                .select2-results__option {
                    margin-left: -$s2bs-padding-base-horizontal*2;
                    padding-left: $s2bs-padding-base-horizontal*3;

                    .select2-results__option {
                        margin-left: -$s2bs-padding-base-horizontal*3;
                        padding-left: $s2bs-padding-base-horizontal*4;

                        .select2-results__option {
                            margin-left: -$s2bs-padding-base-horizontal*4;
                            padding-left: $s2bs-padding-base-horizontal*5;

                            .select2-results__option {
                                margin-left: -$s2bs-padding-base-horizontal*5;
                                padding-left: $s2bs-padding-base-horizontal*6;
                            }
                        }
                    }
                }
            }
        }
    }

    .select2-results__group {
        color: $s2bs-dropdown-header-color;
        display: block;
        padding: $s2bs-dropdown-header-padding-vertical $s2bs-padding-base-horizontal;
        font-size: $s2bs-dropdown-header-font-size;
        line-height: $s2bs-line-height-base;
        white-space: nowrap;
    }

    &.select2-container--focus,
    &.select2-container--open {
        .select2-selection {
            border-color: $s2bs-input-border-focus;

            @if $s2bs-enable-shadows {
                box-shadow: $s2bs-form-control-box-shadow, $s2bs-form-control-focus-box-shadow;
            }

            @else {
                box-shadow: $s2bs-form-control-focus-box-shadow;
            }
        }
    }

    &.select2-container--open {
        /**
       * Make the dropdown arrow point up while the dropdown is visible.
       */

        .select2-selection .select2-selection__arrow b {
            border-color: transparent transparent $s2bs-dropdown-arrow-color transparent;
            border-width: 0 $s2bs-caret-width-base $s2bs-caret-width-base $s2bs-caret-width-base;
        }

        /**
       * Handle border radii of the container when the dropdown is showing.
       */

        &.select2-container--below {
            .select2-selection {
                @include border-bottom-radius(0);
                border-bottom-color: transparent;
                box-shadow: none;
            }
        }

        &.select2-container--above {
            .select2-selection {
                @include border-top-radius(0);
                border-top-color: transparent;
                box-shadow: none;
            }
        }
    }

    /**
     * Clear the selection.
     */

    .select2-selection__clear {
        color: $s2bs-clear-selection-color;
        cursor: pointer;
        float: right;
        font-weight: bold;
        margin-right: 10px;

        &:hover {
            color: $s2bs-clear-selection-hover-color;
        }
    }

    /**
     * Address disabled Select2 styles.
     *
     * @see https://select2.github.io/examples.html#disabled
     * @see hhttps://getbootstrap.com/docs/4.0/components/forms/#disabled-forms
     */

    &.select2-container--disabled {
        .select2-selection {
            border-color: $s2bs-input-border;
            @include box-shadow(none);
        }

        .select2-selection,
        .select2-search__field {
            cursor: $s2bs-cursor-disabled;
        }

        .select2-selection,
        .select2-selection--multiple .select2-selection__choice {
            background-color: $s2bs-input-bg-disabled;
        }

        .select2-selection__clear,
        .select2-selection--multiple .select2-selection__choice__remove {
            display: none;
        }
    }





    /*------------------------------------*\
        #DROPDOWN
    \*------------------------------------*/

    /**
     * Dropdown border color and box-shadow.
     */

    .select2-dropdown {
        @include box-shadow($s2bs-dropdown-box-shadow);
        border-color: $s2bs-input-border-focus;
        overflow-x: hidden;
        margin-top: -1px;

        &--above {
            @include box-shadow($s2bs-dropdown-box-shadow-above);
            margin-top: 1px;
        }
    }

    /**
     * Limit the dropdown height.
     */

    .select2-results>.select2-results__options {
        max-height: 200px;
        overflow-y: auto;
    }





    /*------------------------------------*\
        #SINGLE SELECT2
    \*------------------------------------*/

    .select2-selection--single {
        height: $s2bs-input-height-base;
        line-height: $s2bs-line-height-base;
        padding: $s2bs-padding-base-vertical (
            $s2bs-padding-base-horizontal + $s2bs-caret-width-base*3) $s2bs-padding-base-vertical $s2bs-padding-base-horizontal;

        /**
       * Adjust the single Select2's dropdown arrow button appearance.
       */

        .select2-selection__arrow {
            position: absolute;
            bottom: 0;
            right: $s2bs-padding-base-horizontal;
            top: 0;
            width: $s2bs-caret-width-base;

            b {
                border-color: $s2bs-dropdown-arrow-color transparent transparent transparent;
                border-style: solid;
                border-width: $s2bs-caret-width-base $s2bs-caret-width-base 0 $s2bs-caret-width-base;
                height: 0;
                left: 0;
                margin-left: -$s2bs-caret-width-base;
                margin-top: -$s2bs-caret-width-base * .5;
                position: absolute;
                top: 50%;
                width: 0;
            }
        }

        .select2-selection__rendered {
            color: $s2bs-input-color;
            padding: 0;
        }

        .select2-selection__placeholder {
            color: $s2bs-input-color-placeholder;
        }
    }





    /*------------------------------------*\
      #MULTIPLE SELECT2
    \*------------------------------------*/

    .select2-selection--multiple {
        min-height: $s2bs-input-height-base;
        padding: 0;
        height: auto;

        .select2-selection__rendered {
            box-sizing: border-box;
            display: inline;
            line-height: 1.3;
            list-style: none;
            margin: 0;
            overflow: hidden;
            padding: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .select2-selection__placeholder {
            color: $s2bs-input-color-placeholder;
            float: left;
            margin-top: 5px;
        }

        /**
       * Make Multi Select2's choices match Bootstrap 4's default button styles.
       */

        .select2-selection__choice {
            color: $s2bs-input-color;
            background: $s2bs-btn-default-bg;
            border: 1px solid $s2bs-btn-default-border;
            border-radius: $s2bs-selection-choice-border-radius;
            cursor: default;
            float: left;
            margin: calc(#{$s2bs-padding-base-vertical} - 1px) 0 0 ($s2bs-padding-base-horizontal * .5
        );
        padding: 0 $s2bs-padding-base-vertical;
    }

    /**
       * Minus 2px borders.
       */

    .select2-search--inline {
        .select2-search__field {
            background: transparent;
            padding: 0.375rem $s2bs-padding-base-horizontal;
            height: $s2bs-input-height-base; // calc(1.5em + 0.75rem + 2px);
            line-height: $s2bs-line-height-base;
            margin: -$border-width 0; // Compensate for input borders included in height
            min-width: 5em;
        }
    }

    .select2-selection__choice__remove {
        color: $s2bs-remove-choice-color;
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        margin-right: $s2bs-padding-base-vertical * .5;
        border: 1px solid transparent;
        background-color: transparent;

        &:hover {
            color: $s2bs-remove-choice-hover-color;
        }
    }

    /**
       * Clear the selection.
       */

    .select2-selection__clear {
        margin-top: $s2bs-padding-base-vertical;
    }
}





/**
     * Address Bootstrap control sizing classes
     *
     * 1. Reset Bootstrap defaults.
     * 2. Adjust the dropdown arrow button icon position.
     *
     * @see https://getbootstrap.com/docs/4.0/components/forms/#sizing
     */

/* 1 */
.select2-selection--single.form-control-sm,
.input-group-sm & .select2-selection--single,
.form-group-sm & .select2-selection--single {
    @include border-radius($s2bs-border-radius-small);
    font-size: $s2bs-font-size-small;
    height: $s2bs-input-height-small;
    line-height: $s2bs-line-height-small;
    padding: $s2bs-padding-small-vertical $s2bs-padding-small-horizontal + $s2bs-caret-width-base*3 $s2bs-padding-small-vertical $s2bs-padding-small-horizontal;

    /* 2 */
    .select2-selection__arrow b {
        margin-left: -$s2bs-padding-small-vertical;
    }
}

.select2-selection--multiple.form-control-sm,
.input-group-sm & .select2-selection--multiple,
.form-group-sm & .select2-selection--multiple {
    @include border-radius($s2bs-border-radius-small);
    min-height: $s2bs-input-height-small;

    .select2-selection__choice {
        font-size: $s2bs-font-size-small;
        line-height: $s2bs-line-height-small;
        margin: calc(#{$s2bs-padding-small-vertical} - 1px) 0 0 ($s2bs-padding-small-horizontal * .5);
        padding: 0 $s2bs-padding-small-vertical;
    }

    .select2-search--inline .select2-search__field {
        padding: 0 $s2bs-padding-small-horizontal;
        font-size: $s2bs-font-size-small;
        height: $s2bs-input-height-small;
        line-height: $s2bs-line-height-small;
    }

    .select2-selection__clear {
        margin-top: $s2bs-padding-small-vertical;
    }
}

.select2-selection--single.form-control-lg,
.input-group-lg & .select2-selection--single,
.form-group-lg & .select2-selection--single {
    @include border-radius($s2bs-border-radius-large);
    font-size: $s2bs-font-size-large;
    height: $s2bs-input-height-large;
    line-height: $s2bs-line-height-large;
    padding: $s2bs-padding-large-vertical $s2bs-padding-large-horizontal + $s2bs-caret-width-large*3 $s2bs-padding-large-vertical $s2bs-padding-large-horizontal;

    /* 1 */
    .select2-selection__arrow {
        width: $s2bs-caret-width-large;

        b {
            border-width: $s2bs-caret-width-large $s2bs-caret-width-large 0 $s2bs-caret-width-large;
            margin-left: -$s2bs-caret-width-large;
            margin-left: -$s2bs-padding-large-vertical;
            margin-top: -$s2bs-caret-width-large * .5;
        }
    }
}

.select2-selection--multiple.form-control-lg,
.input-group-lg & .select2-selection--multiple,
.form-group-lg & .select2-selection--multiple {
    min-height: $s2bs-input-height-large;
    border-radius: $s2bs-border-radius-large;

    .select2-selection__choice {
        font-size: $s2bs-font-size-large;
        line-height: $s2bs-line-height-large;
        border-radius: $s2bs-selection-choice-border-radius;
        margin: calc(#{$s2bs-padding-large-vertical} - 1px) 0 0 ($s2bs-padding-large-horizontal * .5);
        padding: 0 $s2bs-padding-large-vertical;
    }

    .select2-search--inline .select2-search__field {
        padding: 0 $s2bs-padding-large-horizontal;
        font-size: $s2bs-font-size-large;
        height: $s2bs-input-height-large;
        line-height: $s2bs-line-height-large;
    }

    .select2-selection__clear {
        margin-top: $s2bs-padding-large-vertical;
    }
}

.select2-selection.form-control-lg.select2-container--open {
    @include dropdown-arrow;
}

.input-group-lg & .select2-selection {
    &.select2-container--open {
        @include dropdown-arrow;
    }
}





/*------------------------------------*\
      #RTL SUPPORT
    \*------------------------------------*/

&[dir="rtl"] {

    /**
       * Single Select2
       *
       * 1. Makes sure that .select2-selection__placeholder is positioned
       *    correctly.
       */

    .select2-selection--single {
        padding-left: $s2bs-padding-base-horizontal + $s2bs-caret-width-base*3;
        padding-right: $s2bs-padding-base-horizontal;

        .select2-selection__rendered {
            padding-right: 0;
            padding-left: 0;
            text-align: right;
            /* 1 */
        }

        .select2-selection__clear {
            float: left;
        }

        .select2-selection__arrow {
            left: $s2bs-padding-base-horizontal;
            right: auto;

            b {
                margin-left: 0;
            }
        }
    }

    /**
       * Multiple Select2
       */

    .select2-selection--multiple {

        .select2-selection__choice,
        .select2-selection__placeholder,
        .select2-search--inline {
            float: right;
        }

        .select2-selection__choice {
            margin-left: 0;
            margin-right: $s2bs-padding-base-horizontal * .5;
        }

        .select2-selection__choice__remove {
            margin-left: 2px;
            margin-right: auto;
        }
    }
}

.select2-dropdown[dir="rtl"] {
    .select2-results__options {
        text-align: right;
    }
}
}





/*------------------------------------*\
    #ADDITIONAL GOODIES
  \*------------------------------------*/

/**
   * Address Bootstrap's validation states
   *
   * If a Select2 widget parent has one of Bootstrap's validation state modifier
   * classes, adjust Select2's border colors and focus states accordingly.
   * You may apply said classes to the Select2 dropdown (body > .select2-container)
   * via JavaScript match Bootstraps' to make its styles match.
   *
   * @see https://getbootstrap.com/docs/4.0/components/forms/#validation
   */

.is-valid {
    @include validation-state-focus($s2bs-state-success-text);
}

.is-invalid {
    @include validation-state-focus($s2bs-state-danger-text);
}

/* Validation classes on parent element. Preserved Bootstrap 3 validation classes */

.has-warning {
    @include validation-state-focus($s2bs-state-warning-text);
}

.has-error {
    @include validation-state-focus($s2bs-state-danger-text);
}

.has-success {
    @include validation-state-focus($s2bs-state-success-text);
}

/**
   * Select2 widgets in Bootstrap Input Groups
   *
   * @see https://getbootstrap.com/docs/4.0/components/input-group/
   * @see https://github.com/twbs/bootstrap/blob/v4.4.1/scss/_input-group.scss
   */

/**
   * Reset rounded corners
   */

.input-group>.select2-hidden-accessible {

    &:not(:first-child)+.select2-container--bootstrap>.selection>.select2-selection,
    &:not(:first-child)+.select2-container--bootstrap>.selection>.select2-selection.form-control {
        @include border-left-radius(0);
    }

    +.select2-container--bootstrap:not(:last-child)>.selection>.select2-selection,
    +.select2-container--bootstrap:not(:last-child)>.selection>.select2-selection.form-control {
        @include border-right-radius(0);
    }
}

.input-group>.select2-container--bootstrap {
    flex: 1 1 auto;
    position: relative;
    z-index: 2;
    width: 1%;
    margin-bottom: 0;

    >.selection {
        display: flex;
        flex: 1 1 auto;

        >.select2-selection.form-control {
            float: none;
        }
    }

    /**
     * Adjust z-index like Bootstrap does to show the focus-box-shadow
     * above appended buttons in .input-group and .form-group.
     */

    &.select2-container--open,
    /* .form-group */
    &.select2-container--focus

    /* .input-group */
        {
        z-index: 3;
    }

    /**
     * Adjust alignment of Bootstrap buttons in Bootstrap Input Groups to address
     * Multi Select2's height which - depending on how many elements have been selected -
     * may grow taller than its initial size.
     *
     * @see https://github.com/twbs/bootstrap/blob/v4.4.1/scss/_input-group.scss
     */

    &,
    .input-group-append,
    .input-group-prepend,
    .input-group-append .btn,
    .input-group-prepend .btn {
        vertical-align: top;
    }
}

/**
   * Temporary fix for https://github.com/select2/select2-bootstrap-theme/issues/9
   *
   * Provides `!important` for certain properties of the class applied to the
   * original `<select>` element to hide it.
   *
   * @see https://github.com/select2/select2/pull/3301
   * @see https://github.com/fk/select2/commit/31830c7b32cb3d8e1b12d5b434dee40a6e753ada
   */

.form-control.select2-hidden-accessible {
    position: absolute !important;
    width: 1px !important;
}

/**
   * Display override for inline forms
   */

.form-inline .select2-container--bootstrap {
    @include media-breakpoint-up(sm) {
        display: inline-block;
    }
}