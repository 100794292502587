$header-height: 90px;
$header-height-small: 60px;
$aside-width: 105px;
$footer-height: 100px;

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';

@import '~datatables.net-bs4/css/dataTables.bootstrap4';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4';
@import '~datatables.net-responsive-bs4/css/responsive.bootstrap4';

@import '~select2/dist/css/select2';
@import 'select2';

@import '~@uppy/core/dist/style.min.css';
@import '~@uppy/dashboard/dist/style.min.css';


html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  background-color: $gray-200;
  display: flex;
}

#sessions-new {
  width: 100%;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;

  >form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;

    input[type="email"] {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    input[type="password"] {
      margin-bottom: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      font-size: 16px;
    }
  }
}

#app {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;

  >header {
    position: relative;
    display: flex;
    min-height: $header-height;

    &.open {
      right: 0;
    }

    @include media-breakpoint-down(sm) {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: calc(100vh - #{$header-height-small});
      top: $header-height-small;
      right: -105%;
      flex-direction: column;
      transition: right .25s;
      background: $white;

      //> nav {
      //  order: 1;
      //  flex-grow: 0;
      //  //position: relative;
      //}
      //
      //.navbar-nav {
      //  flex-basis: 100%;
      //  flex-grow: 1;
      //  justify-content: center;
      //  position: relative;
      //}
    }

    >nav {
      @extend .bg-white, .shadow-sm;

      padding: 0;
      flex-grow: 1;

      @include media-breakpoint-down(sm) {
        flex-grow: 0;
        //flex-direction: column;
        //order: 1;
        box-shadow: none !important;
        flex-wrap: nowrap;
        align-items: stretch;
      }

      .navbar-header {
        margin-right: auto;
        height: 100%;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
          display: flex;
          align-items: center;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: $header-height-small;
          background: $white;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        }
      }

      .navbar-toggler {
        height: 100%;
        color: $white;
        background-color: theme-color(primary);
        border: none;
        border-radius: 0;
        padding: 0 20px;

        i {
          margin-right: 10px;
        }
      }

      .navbar-brand {
        @extend .border-right;
        min-width: $aside-width;
        height: 100%;
        text-align: center;

        .portal-icon {
          //max-width: 100%;
          background-image: $portal-icon;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          background-origin: content-box;
          height: 100%;
          padding: 10px;
        }
      }

      .nav-item {
        @extend .border-right;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        padding: 0 20px !important;
        height: 100%;

        i {
          margin-right: 10px;
          font-size: 28px;

          @include media-breakpoint-down(md) {
            margin-right: 0;
          }

          @include media-breakpoint-down(sm) {
            display: block;
            vertical-align: middle;
            font-size: 32px;
            margin-bottom: 5px;
          }
        }

        &:hover,
        &.active,
        .nav-link:hover,
        .nav-link:active {
          color: theme-color-level(secondary, 1) !important;
        }

        @include media-breakpoint-down(md) {
          flex-direction: column;
          justify-content: center;
        }

        @include media-breakpoint-down(sm) {
          flex: 1;
          display: block;
          //border-bottom: 1px solid theme-color-level(light, 5);
          text-align: center;
          padding: 17px 0 20px !important;
        }
      }

    }

    >aside {
      position: fixed;
      top: $header-height;
      left: 0;
      width: $aside-width;
      height: calc(100vh - #{$header-height});
      overflow-y: auto;

      @include media-breakpoint-down(sm) {
        position: relative;
        height: auto;
        width: auto;
        top: auto;
        left: auto;
        flex: 1;
        //top: $header-height-small;
        //height: calc(100vh - #{$header-height-small});
        //display: none;
        //height: 100vh;
      }

      .nav-item {
        border-bottom: 1px solid theme-color-level(primary, 5);

        font-size: 12px;
        font-weight: 600;
        color: $white;
        text-align: center;
        padding: 17px 0 20px;

        .fa-stack {
          display: block;
          margin: 0 auto 5px;
          font-size: 16px;
        }

        >i {
          display: block;
          vertical-align: middle;
          font-size: 32px;
          margin-bottom: 5px;
        }

        &.active,
        &.active:hover {
          background-color: theme-color-level(secondary, 1);
        }

        &:hover {
          background-color: theme-color-level(primary, -1);
        }
      }
    }
  }
}

#app-wrapper {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  margin-left: $aside-width;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-top: $header-height-small;
  }

  >main {
    flex: 1;
    padding: 40px 80px;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      padding: 20px 40px;
    }

    @include media-breakpoint-down(xs) {
      padding: 20px 0;
    }
  }

  >footer {
    @extend .shadow;

    background-color: $white;

    @include media-breakpoint-down(sm) {
      height: auto;
      margin-left: 0;

      .nav {
        flex-direction: column;
      }
    }

    .nav {
      min-height: $footer-height;
      align-items: center;

      .portal-logo {
        height: 80px;
        width: 150px;
        background-image: $portal-logo;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: content-box;
      }
    }
  }
}


.custom-control.image-checkbox {
  position: relative;
  padding-left: 0;

  .custom-control-input:checked~.custom-control-label {

    &:after,
    &:before {
      opacity: 1;
    }
  }

  label {
    cursor: pointer;

    &:before {
      border-color: #007bff;
      background-color: #007bff;
    }

    &:after,
    &:before {
      transition: opacity .3s ease;
      opacity: 0;
      left: .25rem;
    }

    &:focus,
    &:hover {
      opacity: .8;
    }

    img {
      border-radius: 2.5px;
    }
  }
}

#sessions-new {
  .portal-logo {
    height: 90px;
    background-image: $portal-logo;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
  }
}

.form-group-image-checkbox.is-invalid {
  label {
    color: $form-feedback-invalid-color;
  }

  .invalid-feedback {
    display: block;
  }
}

.clickable-row {
  cursor: pointer;
}

.custom-content img {
  @extend .img-fluid;
}

.daterange {

  &:disabled,
  &[readonly] {
    background-color: #fff;
  }
}